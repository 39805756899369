import React from 'react'

const Loading = () => {
  return (
    
      <div className="loader"></div>

  )
}

export default Loading
